import React from 'react';

const DecoSquares = ({ position }) => {
  const rows = 25;
  const columns = 6;
  const squares = [];

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < columns; col++) {
      squares.push(<div className="w-1 h-1 bg-iOrange opacity-60 rounded-2xl box-border"></div>);
    }
  }

  return (
    <div className={`fixed ${position === 'left' ? 'left-2' : 'right-2'} h-screen`}>
      <div className="flex flex-col items-center justify-center h-full">
        <div className="grid grid-cols-6 gap-5">
          {squares}
        </div>
      </div>
    </div>
  );
};

export default DecoSquares;
